@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url('../static/fonts/Poppins/Poppins-Bold.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('../static/fonts/Poppins/Poppins-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url('../static/fonts/Poppins/Poppins-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../static/fonts/Poppins/Poppins-Regular.ttf') format('opentype');
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.header__offcanvas {
  margin-top: 69px !important;
  border: none !important;
  width: 100% !important;
  transition: 0.5s !important;
}

body {
}

.app {
  font-family: Poppins, sans-serif !important;
}

.header {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .header__back {
  display: flex;
  align-items: center;
  height: 49px;
}

.back-button {
  display: flex;
  outline: none;
  border: none;
  background: none;
  align-items: center;
}

.back-button .backbutton__text {
  font-size: 14px;
  line-height: 21px;
}

.header .header__burger-icon {
  margin-left: 40px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.header .header__burger-icon .header__burger-line-top,
.header .header__burger-icon .header__burger-line-bottom {
  transform: rotate(0deg);
  position: absolute;
  transition: 0.35s;
}

.header .header__burger-icon .header__burger-line-bottom {
  top: 3px;
  bottom: 3px;
}

.header .header__burger-icon {
  position: relative;
}

.header .header__burger-icon .header__burger-line {
  height: 2px;
  width: 100%;
  background-color: black;
  transition: 0.35s;
}

.header
  .header__burger-icon.header__burger-icon--isActive
  .header__burger-line-top {
  transform: rotate(-45deg);
}

.header
  .header__burger-icon.header__burger-icon--isActive
  .header__burger-line-bottom {
  transform: rotate(45deg);
  top: 10px;
}

.header__info-box {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  margin: 40px auto 0;
  background-color: #fbfbff;
  padding: 31px 21px;
  max-width: 420px;
  width: 100%;
  border-radius: 15px;
}

.header__info-box .header__info-text {
  display: flex;
  flex-direction: column;
}

.header__info-box img {
  margin-top: -2px;
}

.header__info-box .header__info-text .header__info-textline {
  font-family: Poppins, sans-serif !important;
  font-size: 16px;
  line-height: 130%;
  color: #70748e;
}

.header__info-box .header__info-text .header__info-link {
  margin-top: 7px;
  font-family: 'Poppins';
  color: #fa3269;
  font-weight: 600;
  font-size: 18px;
  font-weight: 600;
  line-height: 120%;
  text-decoration: none;
}

.logo {
  display: flex;
  align-items: center;
}

.logo .logo__image {
  height: 36px;
  width: 36px;
}

.logo .logo__text {
  margin-left: 6px;
  font-weight: 700;
  font-size: 14px;
}

.link-list {
  font-family: 'Poppins';
  display: block;
  margin: 35px auto 0;
  max-width: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 26px;
  line-height: 34px;
  padding: 0;
}

.link-list .link-list__item:not(:first-child) {
  margin-top: 30px;
}

.link-list .link-list__item .accordion .accordion-button {
  color: #1c1e21;
  padding: 0;
  background: none;
  box-shadow: none;
  font-size: 26px;
  font-weight: 600;
}

.link-list .link-list__item .accordion .accordion-body {
  padding: 0;
}

.link-list .link-list__item .accordion .accordion-body ul {
  margin-left: 20px;
  margin-top: 30px;
  padding: 0;
}

.link-list .link-list__item .accordion .accordion-body ul li {
  padding-bottom: 12px;
  border-bottom: 1px solid #f2f3f5;
  font-size: 17px;
}

.link-list .link-list__item .accordion .accordion-body ul li:not(:first-child) {
  margin-top: 12px;
}

.link-list .link-list__item .link-list__link {
  text-decoration: none;
  color: #1c1e21;
}

.link-list
  .link-list__item
  .accordion
  .accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.link-list .link-list__link {
  text-decoration: none;
  color: #1c1e21;
}

.link-list__link:active {
  color: #fa3269;
}

.photo-slider {
  margin-top: 20px;
  transition: 0.5s;
}

.photo-slider .photo-slider__img-block {
  max-height: 330px;
  margin: 0 auto;
  transition: 0.5s;
  position: relative;
}

.photo-slider .photo-slider__img-block .photo-slider__image {
  display: flex;
  height: 100%;
  max-height: 330px;
  object-fit: contain;
  width: 100%;
}

.photo-slider {
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  min-height: 0;
  min-width: 0;
}

.swiper-wrapper {
  margin-top: 20px;
}

.swiper-slide {
  max-height: 333px;
}

.swiper-slide-prev .photo-slider__img-block {
  transform: rotate(-5deg);
  transition: 0.5s;
}

.swiper-slide-next .photo-slider__img-block {
  transform: rotate(5deg);
  transition: 0.5s;
}

.swiper-slide-active {
  transform: rotate(0deg) !important;
  transition: 0.5s;
}

.swiper-pagination-bullet {
  background-color: white !important;
  opacity: 1;
  transition: 0.5s;
}

.swiper-pagination-bullet-active {
  border-radius: 15px !important;
  width: 50px !important;
}

.upload-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.upload-block .upload-block__heading {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, #54d6ff, #3c4ee8);
  -webkit-background-clip: text;

  font-size: 32px;
  line-height: 35px;
  font-weight: 700;

  display: block;
  text-align: center;
  margin: 17px 30px;
}

.upload-block__rating {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.upload-block__rating .upload-block__rating-image {
  height: 17px;
}

.upload-block__rating .upload-block__rating-text {
  margin-left: 6px;
  color: #1c1e21;
  font-size: 13px;
  line-height: 20px;
  opacity: 0.76;
}

.upload-block .upload-block__upload-photo-wrapper {
  position: relative;
  background: #fa3269;
  border-radius: 40px;
  cursor: pointer;
  padding: 18px 50px;
}

.upload-block
  .upload-block__upload-photo-wrapper.upload-block__upload-photo-wrapper--disabled {
  background-color: #ffd1dd;
}

.upload-block .upload-block__skip-link.upload-block__skip-link--disabled {
  cursor: default;
  opacity: 0.2;
  pointer-events: none;
}

.upload-block .upload-block__upload-photo-input {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1c1e21;
  border-radius: 40px;
  display: none;
}

.upload-block .upload-block__upload-button {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: white;
}

.upload-block .upload-block__skip-link {
  cursor: pointer;
  font-size: 15px;
  line-height: 25px;
  margin: 15px 0 10px;
  opacity: 0.6;
  text-decoration: underline;
  color: #1c1e21;
  margin-top: 15px;
}

.upload-block .upload-block__policy-agreement {
  display: flex;
  align-items: center;
  padding: 0 20px 20px;
}

.upload-block .upload-block__button-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-block .upload-block__policy-agreement .upload-block__policy-text {
  margin-left: 10px;
  color: #1c1e21;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 130%;
  opacity: 0.6;
}

.upload-block
  .upload-block__policy-agreement
  .upload-block__policy-text
  .upload-block__policy-link {
  text-decoration: underline;
  cursor: pointer;
}

.main {
  background-image: url('../pages/MainPage/images/background.png');
  background-position: center;
  height: calc(100vh - 89px);
  background-repeat: no-repeat;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;
}

.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 335px;
  margin: 0 auto;
}

.account .account__title {
  margin-top: 40px;
  color: #1c1e21;
  font-size: 26px;
  font-weight: 600;
  line-height: 130%;
}

.account .account__text {
  margin: 23px 0 0;
  max-width: 200px;
  text-align: center;
  color: #1c1e21;
}

.account .account__input {
  margin-top: 30px;
  background: white;
  border-radius: 13px;
  font-size: 16px;
  line-height: 22px;
  max-height: 50px;
  padding: 15px 29px 16px;
  width: 100%;
  border: 1px solid #1b183021;
  border-radius: 13px;
}

.account .account__input:focus {
  outline: none;
}

.account .account__input::placeholder {
  color: #1b183021;
}

.account .account__button {
  font-family: 'Poppins';
  margin-top: 20px;
  width: 100%;
  background: #fa3269;
  border: none;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  opacity: 1;
  padding: 18px 50px;
  text-align: center;
  transition: all 0.35s;
}

.account .account__link {
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 15px;
  margin-top: 30px;
  opacity: 0.8;
  text-decoration: none;
  text-transform: capitalize;
  color: #1c1e21;
}

.account .account__link:hover {
  color: #1c1e21;
}

.account .account__button:disabled {
  background: #ffd1dd;
}

.faq {
  max-width: 480px;
  margin-top: 30px;
}

.faq .faq__title {
  text-align: center;
  font-size: 35px;
  font-weight: 600;
}

.faq .faq__list {
  margin-top: 30px;
  font-size: 35px;
  font-size: 21px;
}

.faq .faq__list .faq__list-item .faq__list-item-title {
  font-weight: 700;
}

.faq .faq__list .faq__list-item .faq__list-item-text {
  margin-bottom: 32px;
}

.email {
  height: calc(100vh - 69px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background-image: url('../pages/MainPage/images/background.png');
  background-position: center;
  background-repeat: no-repeat;
}

.email .email__wrapper {
  max-width: 335px;
  width: 100%;
}

.email .email__wrapper .email__text {
  margin: 23px 0 0 0;
  text-align: center;
  color: #1c1e21;
  line-height: 25px;
  opacity: 0.6;
}

.email .email__checkboxes {
  padding: 70px 20px;
}

.email .email__checkboxes .email__checkboxes-text {
  color: #858688;
  margin-left: 10px;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 130%;
  text-align: left;
  font-size: 10px;
}

.email .email__checkboxes-wrapper {
  display: flex;
}

.email .email__checkboxes .email__policy-link {
  color: #fa3269;
  text-decoration: none;
}

.email .email__input {
  margin-top: 30px;
  background: white;
  border-radius: 13px;
  font-size: 16px;
  line-height: 22px;
  max-height: 50px;
  padding: 15px 29px 16px;
  width: 100%;
  border: 1px solid #1b183021;
  border-radius: 13px;
}

.email .email__input:focus {
  outline: none;
}

.email .email__input::placeholder {
  color: #1b183021;
}

.email .email__button {
  display: block;
  font-family: 'Poppins';
  margin-top: 20px;
  width: 100%;
  background-color: #fa3269;
  border: none;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  opacity: 1;
  padding: 18px 50px;
  text-align: center;
  transition: all 0.35s;
  text-decoration: none;
}

.email .email__button.email__button--disabled {
  background-color: #ffd1dd;
  pointer-events: none;
  cursor: pointer;
}

.email .email__title {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, #54d6ff, #3c4ee8);
  -webkit-background-clip: text;
  font-size: 32px;
  line-height: 35px;
  font-weight: 700;
  display: block;
  text-align: center;
}

.features-videos {
  display: grid;
  height: 62%;
  max-height: 554px;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, minmax(0, 273px));
  margin: 0 auto;
}

.features-videos__wrapper {
  position: relative;
  background-color: #e9e9ea;
  padding: 2px;
  border-radius: 16px;
  height: 100%;
}

.features-videos .features-videos__video {
  position: absolute;
  border-radius: 16px;
  flex-grow: 1;
  flex-shrink: 1;
  object-fit: cover;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
}

.features-page {
  max-width: 335px;
  margin: 0 auto;
  height: calc(100vh - 137px) !important;
}

.features-page__title {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  margin: 26px auto;
  max-width: 267px;
  text-align: center;
}

.features-page__button {
  position: fixed;
  bottom: 10px;
  width: 100%;
  max-width: 335px;
  background: #fa3269;
  border: none;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  opacity: 1;
  padding: 18px 50px;
  text-align: center;
  transition: all 0.35s;
  text-decoration: none;
}

.features-page__button.features-page__button--disabled {
  background-color: #ffd1dd;
  pointer-events: none;
  cursor: pointer;
}

.features-page__button:hover {
  color: white;
}

.features-page__button:disabled {
  background-color: #ffd1dd;
}

.features-videos .features-videos__wrapper.features-videos__wrapper--active {
  background-color: #fa3269;
}

.progress-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../pages/ProgressPage/images/background.png');
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.progress-block {
  max-width: 355px;
}

.progress-block .progress-block__progressbar {
  margin: 0 auto;
  padding: 15px 0;
  width: calc(100% - 180px);
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #1c1e21 !important;
  font-weight: 600;
  font-size: 24px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #fa3269;
}

.progress-block {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-block .progress-block__title {
  margin-top: 20px;
  color: #212124;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
}

.progress-block .progress-block__subtitle {
  margin-top: 20px;
  font-size: 14px;
  line-height: 25px;
  opacity: 0.6;
  text-align: center;
}

.sale-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* max-width: 475px; */
  height: calc(100vh - 69px);
  margin: 0 auto;
  background-image: url('../pages/MainPage/images/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: -150px;
}

.sale-page__textline {
  max-width: 475px;
}

.sale-page .sale-page__title {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, #54d6ff, #3c4ee8);
  -webkit-background-clip: text;

  font-size: 28px;
  line-height: 35px;
  font-weight: 700;

  padding: 0 70px;

  display: block;
  text-align: center;
}

.sale-page .sale-page__subtitle {
  display: block;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  grid-row: 2/3;
  line-height: 31px;
  text-align: center;
}

.phone-block {
  width: 100%;
  position: relative;
  padding: 20px 20px 0;
  max-width: 475px;
  margin: 0 auto;
}

.phone-block .phone-block__lock-wrapper {
  position: absolute;
  width: 100%;
  z-index: 3;
  left: 0;
  right: 0;
  top: 35%;
  height: 48px;
}

.phone-block .phone-block__lock {
  margin: 0 auto;
  display: block;
  height: 100%;
}

.phone-block .phone-block__text {
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
  z-index: 3;
  padding: 0 70px;
  text-align: center;
  align-items: center;
  color: white;
  display: flex;
  font-size: 24px;
  font-weight: 700;
  justify-content: center;
  line-height: 35px;
}

.phone-block .phone-block__image {
  width: 100%;
}

.phone-block .phone-block__image-phone {
  position: relative;
  width: 100%;
  z-index: 2;
}

.phone-block .phone-block__image-lock {
  border-radius: 59px 59px 0 0;
  height: 100%;
  left: 0;
  padding: 13px 35px 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.sale-page .sale-page__bottom {
  background-color: white;
  width: 100%;
  text-align: center;
  z-index: 2;
  position: relative;
}

.divider {
  background: linear-gradient(
    90deg,
    #ffa844 -1.56%,
    #fa3269 30.6%,
    #b900b4 70.4%,
    #00b9ff 109.3%
  );
  height: 2px;
  width: 100%;
  opacity: 1;
  border: none;
  margin: 0;
}

.sale-page .sale-page__bottom .sale-page__button {
  display: block;
  font-family: 'Poppins';
  margin: 10px auto;
  text-decoration: none;
  width: 100%;
  max-width: 435px;
  background: #fa3269;
  border: none;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  opacity: 1;
  padding: 18px 50px;
  text-align: center;
  transition: all 0.35s;
}

.sale-page .sale-page__controls {
  margin: 0 auto;
  width: 100%;
}

.payment {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 475px;
  margin: 0 auto;
}

.payment .payment__title {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  margin: 25px 0 0;
  text-align: center;
  padding: 0 85px;
}

.payment .payment__link-list {
  padding: 0;
}

.payment .payment__link-list .payment__link-list-item {
  display: flex;
}

.payment .payment__link-list .payment__link-list-item:not(:first-child) {
  margin-top: 20px;
}

.payment
  .payment__link-list
  .payment__link-list-item
  .payment__link-list-item-image {
  height: 20px;
  width: 20px;
}

.payment
  .payment__link-list
  .payment__link-list-item
  .payment__link-list-item-text {
  margin-left: 13px;
  font-size: 15px;
  font-weight: 500;
  line-height: 140%;
  opacity: 0.9;
}

.payment
  .payment__link-list
  .payment__link-list-item
  .payment__link-list-item-link {
  margin-left: 13px;
  color: #1c1e21;
  font-weight: 500;
}

.payment .payment__description {
  color: #6c708a;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 10px auto 30px;
  max-width: 300px;
  text-align: center;
  width: 100%;
}

.payment .payment__description-link {
  color: #6c708a;
}

.payment .payment__price-wrapper {
  background: linear-gradient(
    90deg,
    #ffa844 -1.56%,
    #fa3269 30.6%,
    #b900b4 70.4%,
    #00b9ff 109.3%
  );
  border-radius: 12px;
  display: flex;
  justify-self: center;
  margin: 37px 0 25px;
  max-width: 335px;
  padding: 1.5px;
  position: relative;
  width: 100%;
}

.payment .payment__price-content {
  background: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 30px 25px;
  width: 100%;
}

.payment .payment__price-content .payment__price-content-main {
  color: black;
  font-size: 16px;
  font-weight: 700;
  line-height: 137.3%;
  margin: 0;
}

.payment .payment__price-content .payment__price-content-discount {
  color: #fa3269;
  font-size: 13px;
  font-weight: 400;
  line-height: 137.3%;
  margin: 4px 0 0;
}

.payment .payment__price-content .payment__price-content-old-price {
  color: black;
  font-size: 13px;
  font-weight: 400;
  line-height: 137.3%;
  opacity: 0.5;
  text-decoration-line: line-through;
}

.payment .payment__price-wrapper-discount {
  background: #fa3269;
  border-radius: 200px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  left: 10%;
  line-height: 137.3%;
  padding: 5px 15.5px;
  position: absolute;
  text-transform: uppercase;
  top: -9%;
}

.payment .payment__footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.payment .payment__footer .payment__button {
  display: block;
  font-family: 'Poppins';
  margin: 10px auto;
  text-decoration: none;
  width: 100%;
  max-width: 435px;
  background: #fa3269;
  border: none;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  opacity: 1;
  padding: 18px 50px;
  text-align: center;
  transition: all 0.35s;
}

.discount-block {
  background-color: #f8f8fc;
}

.discount-block .discount-block__headline {
  margin: 0 auto;
  font-size: 26px;
  font-weight: 700;
  line-height: 120%;
  width: fit-content;
  text-align: center;
  max-width: 335px;
}

.discount-block .discount-block__headline .discount-block__upper-title {
  display: block;
  color: #222427;
}

.discount-block .discount-block__headline .discount-block__bottom-title {
  display: block;
  color: #fa3269;
}

.discount-block .discount-block__discount-list {
  margin: 0 auto;
  width: 100%;
  max-width: 375px;
}

.discount-block
  .discount-block__discount-list
  .discount-block__discount-wrapper {
  margin-top: 38px;
}

.discount-block
  .discount-block__discount-list
  .discount-block__discount-wrapper:not(:first-child) {
  margin-top: 100px;
}

.discount-block
  .discount-block__discount-list
  .discount-block__discount-wrapper {
  align-items: center;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  max-width: 375px;
  padding: 1px;
  position: relative;
  width: 100%;
}

.discount-block
  .discount-block__discount-list
  .discount-block__discount-wrapper
  .discount-block__discount-plan {
  background: white;
  border-radius: 13px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  padding: 19px;
  width: 100%;
  border: 1px solid #e9e9ea;
}

.discount-block
  .discount-block__discount-list
  .discount-block__discount-text-top {
  display: flex;
  justify-content: space-between;
}

.discount-block
  .discount-block__discount-list
  .discount-block__discount-text-top {
  display: flex;
  justify-content: space-between;
}

.discount-block__small-text {
  color: #858688;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  margin: 0;
}

.discount-block__discount-text-top {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 6px;
}

.discount-block__discount-text-top span {
  color: #1c1e21;
  font-size: 21px;
  font-weight: 800;
  line-height: 20px;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: line-through;
}

.discount-block__discount-plan-right {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.discount-block__discount-plan-right img {
  position: absolute;
  height: 45px;
  width: 100px;
  right: -10px;
  top: 3px;
}

.offer-plan__price__value {
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 3;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  z-index: 2;
}

.offer-plan__price__value__currency {
  font-size: 17px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin: 0;
}

.offer-plan__price__value__currency .zero {
  font-size: 28px;
  line-height: 20px;
}

.price__right {
  display: flex;
  flex-direction: column;
}

.price__right-every-day {
  color: #222427;
  font-size: 12px;
  letter-spacing: 0.248359px;
  line-height: 10px;
}

.discount-block__discount-plan-three-month {
  margin-top: 18px;
}

.discount-block__button {
  display: block;
  font-family: 'Poppins';
  margin: 10px auto;
  text-decoration: none;
  width: 100%;
  max-width: 375px;
  background: #fa3269;
  border: none;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  opacity: 1;
  padding: 18px 50px;
  text-align: center;
  transition: all 0.35s;
}

.discount-block__button:disabled {
  pointer-events: none;
  cursor: default;
  background-color: #ffd1dd;
}

.discount-block__description {
  display: block;
  max-width: 375px;
  margin: 30px auto 0;
  color: #858688;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 133%;
  opacity: 0.7;
  text-align: center;
}

.discount-block__description {
  display: block;
  max-width: 375px;
  margin: 30px auto 0;
  color: #858688;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 133%;
  opacity: 0.7;
  text-align: center;
  padding-bottom: 70px;
}

.discount-block__description-button {
  color: #858688;
}

.discount-page__logos {
  display: block;
  padding-top: 30px;
  max-width: 375px;
  margin: 0 auto;
}

.discount-block__description-link {
  color: #858688;
}

.discount-page__link-list {
  display: flex;
  justify-content: space-between;
  max-width: 375px;
  margin: 0 auto;
  padding: 15px 26px;
}

.discount-page__link-list .discount-page__link {
  color: #222427;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.248359px;
  line-height: 19px;
  opacity: 0.9;
  text-align: center;
}

.money-back {
  align-items: center;
  background: white;
  border-radius: 14px;
  box-shadow: 0 4px 23px rgb(12 31 61 / 10%);
  display: flex;
  margin: 35px auto 70px;
  max-width: 375px;
  padding: 10px;
  position: relative;
}

.money-back .money-back__content {
  background: white;
  border: 2px solid #fa3269;
  border-radius: 14px;
  height: 100%;
  padding: 30px 29px 20px;
  width: 100%;
}

.money-back .money-back__content .money-back__content-title {
  display: block;
  font-size: 25px;
  font-weight: 600;
  line-height: 125%;
  margin: 0;
  text-align: center;
}

.money-back .money-back__content .money-back__content-text {
  color: rgba(34, 36, 39, 0.7);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin: 15px 0;
  text-align: center;
}

.money-back .money-back__content .money-back__content-text a {
  color: rgba(34, 36, 39, 0.7);
}

.money-back .money-back__content .money-back__content-link {
  font-weight: 700;
}

.money-back .money-back__image {
  position: absolute;
  bottom: -50px;
  height: 87px;
  right: 20px;
  width: 63px;
}

.offer-plan__border__tags {
  position: absolute;
  left: 30%;
  top: -10px;
}

.bestseller {
  background: #299aff;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 700;
  line-height: 100%;
  margin-right: 6px;
  padding: 3px 8px;
}

.discount-off {
  background: #00b84f;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  line-height: 100%;
  padding: 3px 8px;
  text-align: center;
}

.discount-block .discount-block__image {
  display: flex;
  text-align: center;
  margin: 0 auto 50px;
  padding-top: 50px;
  width: 50px;
}

.discount-block
  .discount-block__discount-list
  .discount-block__discount-wrapper
  .discount-block__discount-plan.discount-block__discount-plan--selected {
  border-color: #fa3269;
}

.question-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 69px);
  background-image: url('../pages/QuestionsPage/images/winter-bg.png');
  background-repeat: no-repeat;
  background-position: center;
}

.question-page .question-page__wrapper {
  display: flex;
  flex-direction: column;
}

.question-page .question-page__question {
  padding-top: 100px;
  color: #fa3269;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}

.question-page .question-page__title {
  color: #1c1e21;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  margin: 4px auto 0;
  max-width: 375px;
  text-align: center;
}

.question {
  display: flex;
  align-items: center;
  padding: 18px 17px;
  border: 1px solid #e9e9ea;
  border-radius: 13px;
  background-color: white;
  cursor: default;
}

.question:active {
  border: 1px solid #54d6ff;
}

.question .question__icon {
  width: 29px;
}

.question .question__text {
  color: #1c1e21;
  font-size: 15px;
  line-height: 22px;
  opacity: 0.9;
  margin-left: 16px;
}

.question-page .question-page__questions-list {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 333px;
  flex: 0.75 1;
}

.question-page .question-page__questions-list li:not(:first-child) {
  margin-top: 12px;
}

.question-page .question-page__bubble-list {
  height: 100%;
  margin-bottom: 190px;
  padding: 0;
  padding-bottom: 68px;
  position: relative;
}

.question-page__bubble-list
  .question-page__bubble-wrapper
  .question-page__bubble {
  align-items: center;
  background: white;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: all 0.25s;
  width: 100%;
}

.question-page .question-page__bubble-list-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 375px;
  padding: 0 20px;
  position: relative;
  width: 100%;
  flex: 0.75 1;
}

.question-page .question-page__bubble-list .question-page__bubble-wrapper {
  background: #e9e9ea;
  border: 1px solid #e9e9ea;
  border-radius: 50%;
  padding: 1px;
  position: absolute;
}

.question-page
  .question-page__bubble-list
  .question-page__bubble-wrapper
  .question-page__bubble {
  color: #1c1e21;
  font-size: 13px;
  font-weight: 500;
  line-height: 140%;
  text-align: center;
  border-radius: 50%;
}

.question-page
  .question-page__bubble-list
  .question-page__bubble-wrapper:nth-child(1) {
  width: 121px;
  height: 121px;
  left: 16px;
  top: 0px;
  animation-name: bubble1-flow;
  animation-duration: 1s;
}

.question-page
  .question-page__bubble-list
  .question-page__bubble-wrapper:nth-child(2) {
  width: 79px;
  height: 79px;
  left: 158px;
  top: 8px;
  animation-name: bubble2-flow;
  animation-duration: 1s;
}

.question-page
  .question-page__bubble-list
  .question-page__bubble-wrapper:nth-child(3) {
  width: 85px;
  height: 85px;
  left: 246px;
  top: 13px;
  animation-name: bubble3-flow;
  animation-duration: 1s;
}

.question-page
  .question-page__bubble-list
  .question-page__bubble-wrapper:nth-child(4) {
  width: 98px;
  height: 98px;
  left: 16px;
  top: 128px;
  animation-name: bubble4-flow;
  animation-duration: 1s;
}

.question-page
  .question-page__bubble-list
  .question-page__bubble-wrapper:nth-child(5) {
  width: 106px;
  height: 106px;
  left: 118px;
  top: 94px;
  animation-name: bubble5-flow;
  animation-duration: 1s;
}

.question-page
  .question-page__bubble-list
  .question-page__bubble-wrapper:nth-child(6) {
  width: 100px;
  height: 100px;
  left: 232px;
  top: 110px;
  animation-name: bubble6-flow;
  animation-duration: 1s;
}

.question-page .question-page__button {
  margin: 0 auto 40px;
  display: block;
  font-family: 'Poppins';
  text-decoration: none;
  width: 100%;
  max-width: 375px;
  background: #fa3269;
  border: none;
  border-radius: 40px;
  color: white;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  opacity: 1;
  padding: 18px 50px;
  text-align: center;
  transition: all 0.35s;
}

.question-page .question-page__button.question-page__button--disabled {
  background-color: #ffd1dd;
  cursor: default;
  pointer-events: none;
}

.question-page
  .question-page__bubble-wrapper.question-page__bubble-wrapper--selected {
  border-color: #54d6ff;
  color: #fa3269;
}

.question-page
  .question-page__bubble-wrapper.question-page__bubble-wrapper--selected
  .question-page__bubble {
  color: #fa3269;
}

.creating-account-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding-top: 70px;
  background-image: url('../pages/CreatingAccountPage/images/bg.png');
  background-repeat: no-repeat;
  background-position: center;
}

.creating-account-page .creating-account-page__title {
  padding: 25px 40px;
  color: #222427;
  font-size: 16px;
  font-weight: 500;
  line-height: 130%;
  text-align: center;
  align-items: center;
  border: 1px solid #e9e9ea;
  background-color: white;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 343px;
  width: 100%;
}

.creating-account-page .creating-account-page__image-wrapper {
  margin: 0 auto;
  max-width: 420px;
}

.creating-account-page
  .creating-account-page__image-wrapper
  .creating-account-page__image {
  display: block;
  width: 100%;
}

.creating-account-page
  .creating-account-page__image-wrapper
  .creating-account-page__image-title {
  display: block;
  text-align: center;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, #54d6ff, #3c4ee8);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
}

.creating-account-page
  .creating-account-page__image-wrapper
  .creating-account-page__image-subtitle {
  display: block;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  padding: 18px;
}

@keyframes bubble1-flow {
  0% {
    left: -100px;
    top: -30px;
  }
  100% {
    left: 16px;
    top: 0;
  }
}

@keyframes bubble2-flow {
  0% {
    left: -100px;
    top: -100px;
  }

  100% {
    left: 158px;
    top: 8px;
  }
}

@keyframes bubble3-flow {
  0% {
    left: 350px;
    top: 50px;
  }

  100% {
    left: 246px;
    top: 13px;
  }
}

@keyframes bubble4-flow {
  0% {
    left: -20px;
    top: 80px;
  }

  100% {
    left: 16px;
    top: 128px;
  }
}

@keyframes bubble5-flow {
  0% {
    left: -60px;
    top: 150px;
  }

  100% {
    left: 118px;
    top: 94px;
  }
}

@keyframes bubble6-flow {
  0% {
    left: -50px;
    top: 350px;
  }

  100% {
    left: 232px;
    top: 110px;
  }
}
